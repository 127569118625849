/* General Page Styles */
/* Prevent horizontal overflow */
body, html {
    overflow-x: hidden;
}


.ev-chargers-page {
    font-family: Arial, sans-serif;
    color: #333;
}

/* EV Chargers Overview Section */
.ev-overview {
    position: relative;
    height: 500px; /* Adjust this height as needed */
    width: 100%; /* Full-width container */
    overflow: hidden; /* Prevent overflow to keep it neat */
}

.ev-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the container completely */
    object-position: center; /* Keeps the image centered within the container */
}

/* Keyframes for sliding in from the right */
@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%); /* Start off-screen to the right */
    background: rgba(255, 255, 255, 0.85);
    padding: 20px;
    text-align: center;
    max-width: 90%; /* Adjust width to fit smaller screens */
    width: 90%; /* Ensure it doesn't exceed container width */
    animation: slideInFromRight 1s ease-out forwards; /* Apply the slide-in animation */
}

.overlay-text h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.overlay-text p {
    font-size: 1.2rem;
}

/* Benefits & Features Section */
.benefits-features-section {
    padding: 40px 20px;
    text-align: center;
    background-color: #444;
  }
  
  .benefits-heading {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-out; /* Optional animation for the heading */
    width: 100%; /* Ensures it takes up the full width of the section */
  }
  
  /* Keyframes for fading in the heading */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Styles for the Benefit Cards */
  .benefits-features {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap; /* Ensures cards don't overflow on smaller screens */
  }
  
  .benefit-item {
    width: 30%;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background-color: #333;
    color: #ffffff;
    position: relative;
    opacity: 0; /* Initially hide the element */
    transform: translateY(30px); /* Start position below */
    animation: slideInUp 1s ease-out forwards; /* Apply the slide-in animation */
  }
  
  /* Define animation for sliding up */
  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


/* Specific Styles for Each Benefit Card */
.benefit-item:nth-child(1) {
    background-color: #000; /* Black for Cost Efficient */
    animation-delay: 0.2s;
}

.benefit-item:nth-child(2) {
    background-color: #555; /* Grey for Environmentally Friendly */
    animation-delay: 0.4s;
}

.benefit-item:nth-child(3) {
    background-color: #fff; /* White for Performance & Convenience */
    color: #333; /* Change text color to dark for readability */
    animation-delay: 0.6s;
}

/* Icon Styling */
.icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }

.benefit-item:nth-child(1) .icon {
    color: #ffffff; /* White icon for black card */
}

.benefit-item:nth-child(2) .icon {
    color: #32cd32; /* Green for Environmentally Friendly */
}

.benefit-item:nth-child(3) .icon {
    color: #000; /* Dark icon for white card */
}

/* Title Styles */
.benefit-item h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: inherit; /* Inherit text color */
    text-transform: uppercase;
    font-weight: bold;
}

.benefit-item p {
    font-size: 1rem;
    line-height: 1.5;
}

/* Optional: Add Borders or Separators */
.benefit-item h3::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #32cd32; /* Green separator under title */
    margin: 10px auto;
}


/* Why Choose Us Section Styling */
.why-choose-us {
    padding: 60px 20px;
    background-color: white;
}

.why-choose-us h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
}

/* Initially Hidden State for Items */
.why-item {
    margin-bottom: 30px;
    text-align: center;
    opacity: 0; /* Initially hidden */
    transform: translateY(50px); /* Positioned lower initially */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.why-item.visible {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Move to original position */
}

.why-item h3 {
    font-size: 1.75rem;
    margin-bottom: 10px;
    color: #333;
}

.why-item p {
    font-size: 1rem;
    line-height: 1.5;
}

/* Optional Styling Enhancements */
.why-item h3::after {
    content: '';
    display: block;
    width: 1100px;
    height: 3px;
    background-color: green;
    margin: 10px auto;
}

/* Tablet Config - Screens with max-width 768px */
@media (max-width: 768px) {
    body, html {
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }

    .ev-overview {
        height: 300px;
        width: 100%;
        max-width: 100%;
    }

    .overlay-text {
        padding: 15px; /* Reduce padding for smaller screens */
        font-size: 1rem;
        max-width: 80%; /* Fit it better on smaller devices */
        width: 80%;
        transform: translateX(0); /* Ensure no horizontal offset */
    }

    .benefits-features {
        flex-direction: column;
        gap: 10px;
    }

    .benefit-item {
        width: 100%;
        padding: 15px;
        max-width: 100%;
    }

    .why-choose-us {
        padding: 30px 15px;
        width: 100%;
        max-width: 100%;
        overflow: hidden; /* Prevent overflow */
    }

    .why-choose-us h2 {
        font-size: 2rem;
    }

    .why-item {
        transform: translateY(30px);
        margin-bottom: 20px;
        width: 100%;
        max-width: 100%;
    }

    .why-item h3 {
        font-size: 1.5rem;
    }

    .header {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .benefit-item img, .service-img, .service-card-unique img {
        width: 100%;
        height: auto; /* Keep image proportions */
    }
}

/* Phone Config - Screens with max-width 480px */
@media (max-width: 480px) {
    body, html {
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }

    .ev-overview {
        height: 200px; /* Reduce height for smaller screens */
        width: 100%;
        max-width: 100%;
    }

    .overlay-text {
        padding: 10px; /* Further reduce padding for phones */
        font-size: 0.9rem;
        max-width: 95%; /* Allow more space for text to adjust */
        width: 95%;
    }

    .benefits-heading {
        font-size: 1.8rem;
    }

    .benefit-item {
        padding: 10px;
        width: 100%;
        max-width: 100%;
    }

    .benefit-item h3 {
        font-size: 1.2rem;
    }

    .benefit-item p {
        font-size: 0.9rem;
    }

    .why-choose-us {
        padding: 20px 10px;
        width: 100%;
        max-width: 100%;
    }

    .why-choose-us h2 {
        font-size: 1.8rem;
    }

    .why-item {
        margin-bottom: 15px;
        padding: 15px;
        width: 90%;
        max-width: 90%;
    }

    .why-item h3 {
        font-size: 1.3rem;
    }

    .why-item p {
        font-size: 0.9rem;
    }

    .benefit-item img, .service-img, .service-card-unique img {
        width: 100%;
        height: auto; /* Ensure images stay proportional */
    }

    .header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 15px;
    }
}

  
