/* Solar PV Page General Styling */
.solar-pv-page {
    font-family: Arial, sans-serif;
    color: #333;
}

/* Solar Hero Section */
.solar-hero {
    display: flex;
    width: 100vw;
    height: 50vh;
    padding: 0;
    margin: 0;
    background-color: #f5f5f5;
    align-items: center;
    opacity: 0;  /* Initially hidden */
    transform: translateY(50px); /* Move down by 50px initially */
    transition: transform 1s ease-out, opacity 1s ease-out;
}

.solar-hero.visible {
    opacity: 1;  /* Fade in */
    transform: translateY(0);  /* Slide to original position */
}

.hero-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allow wrapping to avoid overflow */
    width: 100%;
    justify-content: center; /* Center items within the container */
}

.text-container, .image-container {
    flex: 1;                /* Make text and image occupy equal space */
    padding: 10px;             /* Remove padding to ensure full-width use */
    display: flex;
    align-items: center;    /* Vertically align content */
    justify-content: center; /* Horizontally center the text */
}

.text-container {
    flex: 1;                 /* Make text occupy half of the space */
    display: flex;           /* Set display to flex to enable alignment */
    align-items: center;     /* Vertically center the content */
    justify-content: center; /* Horizontally center the text */
    height: 100%;            /* Full height to allow centering */
    padding: 20px;           /* Adds spacing for text readability */
    margin: 0;               /* Remove any margin */
    margin-top: 175px;
}

.text-container h1 {
    font-size: 3rem;         /* Adjust size as needed */
    color: #565454;             /* Dark color for contrast */
    text-align: center;      /* Align text to center */
    margin: 0;               /* Remove default margins */
}


.image-container {
    height: 50%;
}

.image-container img {
    width: 100%;
    height: 350px;           /* Make the image fill the entire container height */
    object-fit: cover;      /* Ensure the image fully covers the container */

}



/* Overview Section Styling */
.solar-overview {
    padding: 60px 20px;
    background-color: #565454;
    color: #fff;
    text-align: center;
}

.solar-overview h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.solar-overview p {
    max-width: 800px;
    margin: 0 auto 40px;
    line-height: 1.5;
    font-size: 1.4rem;
}

/* Benefits & Registration Section */

.benefits-registration-combined {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #767575;
    padding: 40px;
    border-radius: 8px;
    max-width: 1500px;
    margin: 0 auto;
    opacity: 1; /* Start with hidden */
    transform: translateY(20px); /* Slightly below position */
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.benefits-registration-combined.visible {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to original position */
}

.combined-column {
    flex: 1;
    min-width: 300px;
    padding: 20px;
    color: #fff;
    animation: slideIn 1s ease-out forwards;
}

.combined-column h3 {
    font-size: 3rem; /* Increased the heading size */
    margin-top: 5px;
    margin-bottom:15px;
    color: #ddd;
    text-align: center;
    margin-right: 200px;
}

.combined-column ul {
    list-style: none;
    padding: 0;
}

.combined-column li {
    font-size: 2rem; /* Increased the text size */
    line-height: 2; /* Adjusted for better spacing */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    opacity: 0; /* Initially hidden */
    transform: translateX(-20px); /* Initially shifted left */
    animation: fadeSlideIn 1s ease-out forwards;
}

.combined-column li::before {
    content: '✔';
    color: green;
    font-weight: bold;
    margin-right: 10px;
    font-size: 2rem; /* Match the icon size with text */
}

/* Keyframes for animation */
@keyframes fadeSlideIn {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Stagger effect for list items */
.combined-column li:nth-child(1) {
    animation-delay: 0.2s;
}

.combined-column li:nth-child(2) {
    animation-delay: 0.4s;
}

.combined-column li:nth-child(3) {
    animation-delay: 0.6s;
}

.combined-column li:nth-child(4) {
    animation-delay: 0.8s;
}


/* Assessment Section */
.solar-assessment {
    padding: 40px 20px;
    background-color: #fff;
    text-align: center;
    color: #565454;
    transition: transform 1s ease-out, opacity 1s ease-out;
    transform: translateX(-50%); /* Start slightly off-screen to the left */
    opacity: 1; /* Initially visible */
}

.solar-assessment.visible {
    opacity: 1;
    transform: translateX(0);
}

.solar-assessment h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.solar-assessment p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    max-width: 800px;
    margin: 0 auto 40px;
    line-height: 1.5;
}

.contact-button {
    background-color: green;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #006400;
}

/* SEAI Grants Section */
.seai-grants {
    padding: 40px 20px;
    background-color: #565454;
    text-align: center;
    color: #ffffff;
    transition: transform 1s ease-out, opacity 1s ease-out;
    transform: translateX(50%); /* Start slightly off-screen to the right */
    opacity: 1; /* Initially visible */
}

.seai-grants.visible {
    opacity: 1;
    transform: translateX(0);
}

.seai-grants h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.seai-grants p {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto 40px;
    line-height: 1.5;
}

.learn-more-button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.learn-more-button:hover {
    background-color: #555;
}

/* Tablet Config - Screens with max-width 768px */
@media screen and (max-width: 768px) {
    .solar-hero {
        height: 30vh;
        flex-direction: column;
    }

    .text-container {
        margin-top: 50px;
    }

    .image-container img {
        height: auto;
        width: 80%;
    }

    .benefits-registration-combined {
        flex-direction: column;
        padding: 20px;
    }

    .combined-column {
        min-width: 100%;
        padding: 10px;
    }

    .solar-assessment,
    .seai-grants {
        padding: 20px;
    }

    .solar-overview h2 {
        font-size: 2rem;
    }

    .solar-overview p {
        font-size: 1.2rem;
    }
}

/* Phone Config - Screens with max-width 480px */
@media screen and (max-width: 480px) {
    .solar-hero {
        height: auto; /* Allow flexible height */
        flex-direction: column; /* Stack vertically */
    }

    .text-container, .image-container {
        width: 100%; /* Ensure full width for each container */
        padding: 10px;
    }

    .text-container h1 {
        font-size: 2rem; /* Reduce font size for smaller screens */
    }

    .combined-column h3 {
        font-size: 1.5rem;
    }

    .combined-column li {
        font-size: 1.2rem;
    }

    .contact-button,
    .learn-more-button {
        padding: 8px 15px;
        font-size: 0.9rem;
    }

    .benefits-registration-combined {
        padding: 10px;
    }

    .image-container img {
        width: 100%; /* Full width on small screens */
        height: auto;
    }
}
  
