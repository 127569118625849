/* Unique header styling */
.header-unique {
    background-color: hsl(0, 0%, 31%);
    color: #ffffff;
    text-align: left;
    padding: 40px 20px;
    opacity: 1;                /* Initially hidden */
    transform: translateX(90%); /* Start off-screen from the right */
    transition: transform 1s ease-out, opacity 1s ease-out; /* Smooth transition */
}


.header-unique.visible {
    opacity: 1;                /* Make visible */
    transform: translateX(0);  /* Move to the final position */
}

.header-unique h1 {
    margin-bottom: 10px;
    font-size: 2rem;
}

.header-unique p {
    font-size: 50px;
    width: 50%;
}

/* Service introduction styling */
.services-intro-unique {
    padding: 30px;
    color: #565454;
    background-color: #ffffff;
    display: flex;                 
    justify-content: flex-end;     
    flex-direction: column;        
    align-items: flex-end;         
    text-align: right;             
}


.services-intro-unique h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    align-self: flex-end;         
}

.services-intro-unique p {
    font-size: 1.4rem;
    max-width: 900px;             
    margin: 0;                    
}


/* Service cards container */
.services-unique {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    background-color: #ffffff;
}


/* Individual service card styling */
.service-card-unique {
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 1000px; /* Control card width for balance */
    margin: 20px auto;
    display: flex; /* Align image and content side by side */
    gap: 20px;
    flex-direction: column; /* Stack image and content vertically */

}

.service-card-unique:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.service-card-unique img {
    width: 200px; /* Set fixed width for a consistent look */
    height: auto; /* Keep height proportional */
    border-radius: 10px; /* Optional: round corners for a softer look */
}

.content-container {
    display: flex;
    flex-direction: row; /* Align the content and list side by side */
    flex-wrap: wrap; /* Allow items to wrap if there's not enough space */
    width: calc(100% - 220px); /* Ensure content takes up the rest of the card */
    gap: 20px;
}

.service-card-unique .content {
    flex-basis: 50%; /* Make sure the content section takes up half of the available space */
}

.features-list {
    width: 100%;
    margin-top: 10px;
}

.service-card-unique h3 {
    font-size: 1.75rem;
    margin-bottom: 10px;
    color: black;
}

.service-card-unique p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 15px;
    color: black;
    width: 600px;
}

.features-list ul {
    list-style-type: disc;
    padding-left: 20px;
    color: black;
    column-count: 1; /* Split the list into 2 columns */
    margin-bottom: 20px;
}

.features-list ul li {
    font-size: 1.3rem;
    margin-bottom: 5px;
}



.learn-more-btn-unique {
    display: inline-block;
    background-color: #28a745;
    color: #ffffff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.learn-more-btn-unique:hover {
    background-color: #218838;
}




/* Tablet Config - Screens with max-width 768px */
@media screen and (max-width: 1024px) {
    .services-unique {
        grid-template-columns: 1fr;
        gap: 15px;
        text-align: center;
    }

    .service-card-unique {
        padding: 15px;
        max-width: 100%;
        text-align: center;
        align-items: center;
    }

    .service-card-unique h3 {
        font-size: 1.6rem;
        text-align: center;
        margin-left: 300px;
    }

    .service-card-unique p {
        width: 100%; /* Set width to 90% of the card */
        margin: 0 auto; /* Center the paragraph within the card */
        margin-left: 90px;
        font-size: 1rem; /* Adjust paragraph size for readability */
        line-height: 1.5; /* Increase line height for better spacing */
        text-align: center; /* Distribute text evenly across the width */
        margin-left: 150px;
    }


    .service-card-unique img {
        width: 50%;
        height: auto;
    }

    .content-container {
        align-items: center;
    }

    .service-card-unique p {
        width: 100%;
    }

    .features-list {
        width: 100%;
        text-align: left;
        margin-top: 10px;
        margin-left: 150px;
    }

    .features-list ul {
        padding-left: 20px;
        margin: 10px 0;
        list-style: disc;
    }

    .features-list ul li {
        font-size: 1.1rem;
        line-height: 1.4;
    }

    .learn-more-btn-unique {
        font-size: 0.9rem;
        padding: 8px 15px;
        margin-left: 300px;
    }
}

/* Phone Config - Screens with max-width 480px */
@media screen and (max-width: 480px) {
   

    

    .header-unique {
        padding: 20px 10px;
    }

    .header-unique h1 {
        font-size: 1.2rem;
    }

    .header-unique p {
        font-size: 1.5rem;
    }

    .services-intro-unique {
        padding: 15px;
        align-items: center;
        text-align: center;
    }

    .services-intro-unique h2 {
        font-size: 1.5rem;
    }

    .services-unique {
        gap: 10px;
        width: 100%;
        padding: 0 10px; /* Add padding to avoid touching screen edges */
    }

    .service-card-unique {
        padding: 15px;
        width: 100%; /* Make sure card takes the full width available */
        box-sizing: border-box; /* Ensures padding is included within width */
        overflow: hidden; /* Hide any overflow content */
        position: relative; /* Helps manage child overflow */
    }

    .service-card-unique img {
        width: 100%; /* Make image take full width of the card */
        height: auto; /* Ensure the height is proportional */
    }

    .service-card-unique h3 {
        font-size: 1.4rem; /* Reduce font size to avoid overflow */
        text-align: center; /* Center-align heading */
        width: 100%; /* Make heading take up full width */
        margin: 10px 0; /* Add some margin for better spacing */
    }

    .service-card-unique p {
        width: 100%; /* Full width of the card */
        margin: 0 auto; /* Center-align content */
        font-size: 0.9rem; /* Adjust paragraph size for readability */
        line-height: 1.5; /* Increase line height for better spacing */
    }

    .content-container {
        width: 100%; /* Ensure content container takes up full width */
        text-align: center; /* Center the text for balance */
    }

    .features-list ul {
        padding-left: 20px;
    }

    .features-list ul li {
        font-size: 1rem;
        line-height: 1.4;
    }

    .learn-more-btn-unique {
        font-size: 0.9rem;
        padding: 8px 15px;
        margin-top: 10px;
        max-width: 100%; /* Prevent button from overflowing */
        display: block; /* Makes the button take up its own line */
        box-sizing: border-box; /* Ensure padding is considered in width */
    }
    
}
