.gallery-section {
    text-align: center;
    margin: 40px 0;
}

.slideshow-container {
    position: relative;
    max-width: 1000px;
    height: 700px;  /* Set a consistent height */
    margin: auto;
    overflow: hidden;  /* Hide anything that overflows the container */
    background-color: #565454; /* Set a background color to distinguish overflow */
}


.mySlides img {
    width: 100%;
    height: 100%;
    object-fit: contain;  /* Ensures the image fills the entire container while keeping its aspect ratio */
    border-radius: 8px; /* Optional: adds rounded corners */
}

.mySlides {
    display: none;
    height: 100%;  /* Use full container height */
}

.mySlides.active {
    display: block;
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.prev {
    left: 10px; /* Move the prev button to the left edge */
    border-radius: 3px 0 0 3px; /* Optional: adds rounded corners to the left */
}

.next {
    right: 10px; /* Move the next button to the right edge */
    border-radius: 0 3px 3px 0; /* Optional: adds rounded corners to the right */
}

.prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
}

.dot-container {
    text-align: center;
    margin-top: 10px;
}

.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dot.active, .dot:hover {
    background-color: #717171;
}

/* Tablet Config - Screens with max-width 768px */
@media screen and (max-width: 768px) {
    .slideshow-container {
        height: 400px; /* Reduce height for tablets */
    }

    .mySlides img {
        height: auto; /* Allow image to adjust height based on container */
    }

    .prev, .next {
        font-size: 1.2rem; /* Reduce button size for tablets */
        padding: 12px; /* Adjust padding for smaller devices */
    }

    .dot {
        height: 12px;
        width: 12px;
    }

    .gallery-section {
        margin: 30px 10px; /* Adjust spacing for tablets */
    }
}

/* Phone Config - Screens with max-width 480px */
@media screen and (max-width: 480px) {
    .slideshow-container {
        height: auto; /* Further reduce height for phones */
    }

    .mySlides img {
        height: auto; /* Allow image to fit the smaller container */
        height: auto; /* Ensure image height adjusts proportionally */
        object-fit: contain; /* Ensure the entire image is visible */
    }

    .prev, .next {
        font-size: 1rem; /* Reduce button size for better fit */
        padding: 8px; /* Adjust padding for phones */
    }

    .dot {
        height: 10px;
        width: 10px;
    }

    .gallery-section {
        margin: 20px 5px; /* Reduce margin for smaller screens */
    }

    .dot-container {
        margin-top: 5px; /* Reduce spacing above the dot container */
    }
}
  
