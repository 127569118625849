.services {
    padding: 40px;
    background-color: #ffffff; /* Adjust color as needed */
  }
  
  .service {
    margin-bottom: 40px;
    padding: 20px;
    display: flex;
    align-items: center;
  }
  
  .service:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  .service h2 {
    font-size: 3rem;
    color: #333;
    margin-right: 20px;
  }
  
  .service-content {
    max-width: 600px;
  }
  
  .service-img {
    width: 300px;
    height: auto;
    margin: 0 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .service-content h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .service-content p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #666;
  }
  
  .service-content button {
    background-color: #565454;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .service-content button:hover {
    background-color: #565454;
  }
  
  
  .service {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .service.visible {
    opacity: 1;
    transform: translateY(0);
  }

 /* Tablet Config - Screens with max-width 768px */
@media screen and (max-width: 768px) {
  .service {
      flex-direction: column; /* Stack content vertically */
      text-align: center; /* Center align text for better readability */
  }

  .service h2 {
      font-size: 2rem; /* Reduce heading size for tablets */
      margin: 0 0 20px; /* Remove right margin and add bottom margin */
  }

  .service-img {
      width: 100%; /* Make image full-width */
      margin: 20px 0; /* Add top and bottom margin */
  }

  .service-content {
      max-width: 100%; /* Allow content to fill available width */
  }

  .service-content h3 {
      font-size: 1.5rem; /* Reduce subheading size for tablets */
  }

  .service-content p {
      font-size: 0.9rem; /* Reduce paragraph font size */
  }
}

/* Phone Config - Screens with max-width 480px */
@media screen and (max-width: 480px) {
  .service {
      padding: 10px; /* Reduce padding for smaller screens */
  }

  .service h2 {
      font-size: 1.8rem; /* Further reduce heading size for phones */
  }

  .service-content h3 {
      font-size: 1.3rem; /* Smaller subheading size for phones */
  }

  .service-content p {
      font-size: 0.8rem; /* Further reduce paragraph size for phones */
  }

  .service-content button {
      padding: 8px 15px; /* Reduce button padding for phones */
      font-size: 0.9rem; /* Adjust button font size */
  }
}
  