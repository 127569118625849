.contact {
    padding: 40px;
    text-align: center;
    background-color: #1E8709;
    color: #ffffff;
  }
  
  .contact h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .contact form {
    max-width: 600px;
    margin: 0 auto;
    display: grid;
    gap: 20px;
    color: #ffffff;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #ffffff;
  }
  
  .form-group label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .form-group input, .form-group textarea {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button[type="submit"] {
    background-color: black;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  button[type="submit"]:hover {
    background-color: #565454;
  }

  @media screen and (max-width: 768px) {
    .contact {
      padding: 20px;
    }
  
    .contact form {
      width: 100%;
      max-width: 100%;
      padding: 0 20px;
    }
    
    button[type="submit"] {
      padding: 10px 15px;
    }
  }
  
/* Phone Config - Screens with max-width 480px */
@media screen and (max-width: 480px) {
  .contact {
      padding: 20px 5px;
  }

  .contact h2 {
      font-size: 1.6rem; /* Reduced font size for better visibility */
      margin-bottom: 10px;
  }

  .contact p {
      font-size: 0.9rem; /* Reduced paragraph font size */
  }

  .form-group label {
      font-size: 0.9rem;
  }

  .form-group input,
  .form-group textarea {
      font-size: 0.9rem;
      padding: 8px; /* Reduced padding for smaller input fields */
      width: 85%;
  }

  button[type="submit"] {
      font-size: 1rem;
      padding: 10px 15px;
      width: 90%;
  }
}
  