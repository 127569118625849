body {
  font-family: Arial, sans-serif;
  background-color: #565454;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

/* Header CSS */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #565454;
  position: relative;
  height: 100px;
}

.header .logo,
.header .safe-electric {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}



.header .logo img{
  max-height: 200px;
}

.header .safe-electric img {
  max-height: 150px; /* Reduced from 200px to make the image smaller */
}


/* Header End */

.intro {
  text-align: center;
  padding: 40px;
}

.intro h1 {
  font-size: 2.5rem;
}

.intro p {
  max-width: 800px;
  margin: 20px auto;
  font-size: 1.1rem;
  line-height: 1.6;
}

.van-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}



/* Footer Css Start */

.footer {
  background-color: #565454;
  padding: 40px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-logo img {
  max-height: 150px;
}

.footer-logo-text {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.footer-logo-text p {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.footer-logo-text .underline {
  width: 100%;
  height: 2px;
  background-color: #1E8709;
  margin-top: 5px;
}

.footer-info {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-item a {
  color: #1E8709;
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}

.contact-item svg {
  color: #1E8709;
  font-size: 1.2rem;
}

/* Footer Css End */

/* CSS for Privacy Policy */
.privacy-policy {
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  color: #333;
  line-height: 1.6;
}
.privacy-policy p {
  color: white;
}

.privacy-policy h1, .privacy-policy h2 {
  color: #1E8709;
}

.privacy-policy a {
  color: #f0a500;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

/* Fade In/Out Page Transitions */
.transition-group {
  position: relative;
}

.route-section {
  position: absolute;
  width: 100%;
  transition: all 0.5s ease;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.95);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 500ms, transform 500ms;
}



/* Mobile Config for Phones and Tablets */

/* Tablet Config - Screens with max-width 768px */
@media (max-width: 768px) {
  body {
    padding: 0 10px;
  }

  .header {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: auto;
  }

  .header .logo img,
  .header .safe-electric img {
    max-height: 100px;
  }

  .intro {
    padding: 20px;
  }

  .intro h1 {
    font-size: 2rem;
  }

  .intro p {
    font-size: 1rem;
  }

  .services {
    padding: 20px 10px;
  }

  .service {
    flex-direction: column !important;
    align-items: center;
    margin-bottom: 20px;
  }

  .service img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .service-content {
    max-width: 100%;
    text-align: center;
  }

  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .footer-logo img {
    max-height: 80px;
    margin-bottom: 10px;
  }

  .footer-info {
    margin: 10px 0;
  }

  .privacy-policy {
    padding: 20px;
  }
}

/* Phone Config - Screens with max-width 480px */
@media (max-width: 480px) {
  .header {
    padding: 10px;
  }

  .header .logo img,
  .header .safe-electric img {
    max-height: 70px;
  }

  .intro h1 {
    font-size: 1.8rem;
  }

  .intro p {
    font-size: 0.9rem;
  }

  .van-image {
    margin-top: 10px;
  }

  .service-content h2 {
    font-size: 1.5rem;
  }

  .service-content h3 {
    font-size: 1.2rem;
  }

  .service-content p {
    font-size: 0.9rem;
  }

  .footer {
    padding: 15px 5px;
  }

  .footer-logo img {
    max-height: 50px;
    margin-bottom: 5px;
  }

  .footer-logo-text p {
    font-size: 1.2rem;
  }

  .contact-item a {
    font-size: 0.9rem;
  }

  .privacy-policy {
    padding: 15px;
  }

  .privacy-policy h1,
  .privacy-policy h2 {
    font-size: 1.5rem;
  }

  .privacy-policy p {
    font-size: 0.9rem;
  }
}

