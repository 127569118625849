/* General reset for the navbar */
.navbar {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom, #e0e0e0, #c0c0c0); /* Adjust colors for a similar gradient */
    padding: 20px 0;
    text-align: center;
    transition: top 0.5s ease-in-out;
    z-index: 1000;
  }
  
  /* When navbar is open, slide it into view */
  .navbar-open {
    top: 0;
  }
  
  /* Navigation list styling */
  .navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .navbar li {
    margin: 0 10px;
  }
  
  .navbar a {
    text-decoration: none;
    color: #333; /* Adjust color for better contrast with the gradient */
    font-size: 1.2rem;
    transition: color 0.3s;
  }
  
  .navbar a:hover {
    color: #1E8709;
  }
  
  /* Navbar toggle button */
  .navbar-toggle {
    position: fixed;
    top: 20px;
    left: 20px;
    background: linear-gradient(to right, #1E8709, #565454);
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 5px;
    z-index: 1100;
    transition: background-color 0.3s;
  }
  
  .navbar-toggle:hover {
    background: linear-gradient(to right, #565454, #1E8709);
  }
/* Tablet Config - Screens with max-width 768px */
@media screen and (max-width: 768px) {
  .navbar ul {
      flex-direction: column;
      padding: 10px;
      align-items: flex-start; /* Align items to the left for a cleaner look */
  }

  .navbar li {
      margin: 10px 0; /* Adjust spacing between items for better readability */
  }

  .navbar a {
      font-size: 1.1rem; /* Slightly smaller font size for tablets */
  }

  .navbar-toggle {
      font-size: 1.2rem;
      padding: 8px 12px; /* Adjust padding for smaller devices */
  }
}

/* Phone Config - Screens with max-width 480px */
@media screen and (max-width: 480px) {
  .navbar {
      padding: 15px 0; /* Adjust padding for a more compact navbar */
  }

  .navbar ul {
      flex-direction: column;
      align-items: center; /* Align items to the center for better appearance on small screens */
      padding: 5px;
  }

  .navbar li {
      margin: 5px 0; /* Reduce spacing between items for phones */
  }

  .navbar a {
      font-size: 1rem; /* Smaller font size for better fit on phones */
  }

  .navbar-toggle {
      top: 10px;
      left: 10px;
      font-size: 1rem;
      padding: 6px 10px; /* Further reduce padding to make it less intrusive on small screens */
  }
}
  
  